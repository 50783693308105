import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 675.000000 474.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,474.000000) scale(0.100000,-0.100000)" >

<path d="M3350 3993 c-8 -3 -73 -40 -145 -81 -71 -41 -197 -114 -280 -162
-370 -213 -386 -223 -401 -251 -12 -23 -14 -109 -14 -503 l0 -476 28 -29 c15
-17 119 -83 232 -149 446 -258 563 -324 590 -333 34 -12 67 0 183 69 90 54
199 116 477 276 102 59 197 120 213 137 l27 29 0 476 c0 395 -2 480 -14 503
-11 20 -66 58 -198 134 -101 58 -266 153 -368 212 -286 166 -284 165 -330 148z
m432 -261 c208 -120 390 -227 405 -238 l28 -21 3 -455 c1 -271 -1 -465 -7
-480 -5 -15 -32 -38 -63 -56 -67 -38 -307 -177 -555 -320 -106 -62 -200 -112
-209 -112 -8 0 -111 56 -227 123 -117 68 -297 172 -400 232 -139 79 -191 114
-198 133 -6 15 -8 209 -7 480 l3 455 28 21 c15 11 87 55 160 97 73 42 242 140
377 218 134 77 253 141 265 141 11 0 190 -98 397 -218z"/>
<path d="M3003 3667 l-383 -221 0 -446 1 -445 355 -205 c196 -113 368 -211
384 -219 28 -14 37 -9 409 205 l380 219 1 445 0 446 -362 209 c-200 115 -372
214 -383 221 -18 11 -63 -13 -402 -209z m157 -132 l0 -65 -34 0 c-19 0 -41 -3
-50 -6 -14 -5 -16 4 -16 65 l0 71 50 0 50 0 0 -65z m533 53 c-12 -20 -199
-283 -270 -379 -35 -48 -63 -92 -63 -98 0 -6 50 -105 110 -219 61 -115 110
-212 110 -215 0 -4 -24 -7 -54 -7 l-54 0 -50 98 c-28 53 -70 134 -94 179 l-44
82 -62 -83 -62 -84 0 -226 0 -226 -50 0 -50 0 0 465 0 465 50 0 50 0 0 -172 0
-171 215 301 216 302 55 0 c42 0 53 -3 47 -12z m-245 -160 c-8 -13 -21 -33
-28 -44 -13 -21 -22 -22 -214 -26 -312 -6 -316 -11 -316 -348 1 -270 13 -311
103 -340 l47 -15 0 -48 0 -48 -37 6 c-59 10 -140 53 -164 86 -36 52 -49 116
-55 284 -11 292 17 413 107 468 63 38 110 44 358 46 l213 1 -14 -22z m238 1
c72 -26 124 -117 124 -216 l0 -43 -50 0 -50 0 0 35 c0 80 -44 135 -115 142
-25 3 -45 7 -45 9 0 2 14 24 32 48 33 48 39 49 104 25z m131 -551 c-9 -183
-33 -241 -117 -288 -44 -24 -50 -25 -287 -28 l-243 -4 0 45 0 45 213 4 c188 3
217 5 251 23 61 31 77 60 83 154 l6 81 -107 0 -106 0 0 45 0 45 157 0 156 0
-6 -122z m-143 -392 c20 -35 36 -67 36 -70 0 -3 -24 -6 -54 -6 l-55 0 -35 62
c-19 34 -32 66 -30 70 3 4 27 8 54 8 l48 0 36 -64z"/>
<path d="M1467 1634 c-4 -4 -7 -92 -7 -196 l0 -189 43 3 42 3 3 61 c2 44 8 66
21 78 17 16 21 12 65 -64 l46 -80 50 0 c28 0 50 4 50 10 0 5 -27 54 -60 108
-33 55 -60 103 -60 107 0 4 29 40 65 79 36 40 62 76 58 79 -8 8 -61 9 -88 1
-11 -3 -47 -36 -80 -74 l-60 -68 -5 72 -5 71 -35 3 c-20 2 -39 0 -43 -4z"/>
<path d="M1953 1632 c-11 -7 -143 -352 -143 -373 0 -5 20 -9 44 -9 l45 0 42
130 c24 71 46 126 50 122 4 -4 17 -38 29 -77 13 -38 31 -94 42 -122 l19 -53
45 0 c30 0 44 4 44 13 0 26 -129 360 -142 368 -16 11 -59 11 -75 1z"/>
<path d="M2231 1626 c-8 -9 -10 -56 -7 -147 2 -74 5 -156 5 -182 l1 -48 43 3
42 3 3 60 c2 37 9 66 19 77 15 17 19 13 63 -59 45 -76 47 -78 89 -81 24 -2 47
0 52 5 4 4 -13 42 -38 83 -25 41 -54 89 -64 106 l-19 31 66 72 c36 39 63 75
59 81 -3 5 -25 10 -47 10 -38 0 -46 -5 -109 -72 l-69 -73 0 66 c0 52 -4 68
-16 73 -27 10 -61 7 -73 -8z"/>
<path d="M2722 1631 c-14 -8 -142 -340 -142 -367 0 -12 9 -14 43 -12 l42 3 42
120 c23 66 43 124 46 130 2 5 23 -49 48 -120 l44 -130 48 -3 c26 -2 47 1 47 6
0 21 -133 367 -144 374 -15 10 -58 10 -74 -1z"/>
<path d="M3213 1625 c-57 -24 -73 -65 -73 -182 0 -57 5 -113 12 -128 43 -95
247 -93 287 3 7 16 11 55 9 88 l-3 59 -75 0 -75 0 -3 -34 c-4 -37 9 -51 46
-51 30 0 28 -25 -3 -46 -28 -18 -64 -13 -89 12 -22 22 -22 161 0 192 23 33 88
31 110 -3 13 -20 25 -25 55 -25 34 0 39 3 39 24 0 29 -33 72 -70 91 -36 19
-125 19 -167 0z"/>
<path d="M3537 1634 c-4 -4 -7 -92 -7 -196 l0 -189 138 3 137 3 0 35 0 35 -92
3 -92 3 -3 152 -3 152 -35 3 c-20 2 -39 0 -43 -4z"/>
<path d="M3919 1617 c-50 -33 -59 -59 -59 -172 0 -112 9 -139 57 -171 69 -47
161 -38 220 21 l33 33 0 108 c0 122 -8 147 -59 181 -48 32 -144 32 -192 0z
m143 -74 c15 -13 18 -31 18 -98 0 -98 -11 -119 -65 -119 -53 0 -65 21 -65 118
0 60 4 84 17 98 20 22 70 23 95 1z"/>
<path d="M4250 1444 l0 -196 130 3 130 4 31 32 c41 40 44 100 9 138 -22 23
-22 26 -7 43 24 26 22 93 -3 125 -28 35 -76 47 -192 47 l-98 0 0 -196z m224
90 c8 -20 -11 -44 -34 -44 -24 0 -42 -24 -38 -51 2 -14 14 -25 38 -33 40 -13
52 -43 24 -63 -10 -7 -42 -13 -71 -13 l-53 0 0 110 0 110 64 0 c50 0 65 -4 70
-16z"/>
<path d="M4763 1632 c-12 -8 -143 -347 -143 -371 0 -6 18 -11 44 -11 l45 0 42
130 c32 95 46 125 52 114 5 -9 26 -66 46 -128 l38 -111 46 -3 c32 -2 47 1 47
9 0 23 -131 363 -143 371 -7 4 -23 8 -37 8 -14 0 -30 -4 -37 -8z"/>
<path d="M5042 1632 c-9 -7 -12 -52 -10 -193 l3 -184 138 -3 137 -3 0 41 0 40
-90 0 -90 0 0 143 c0 98 -4 147 -12 155 -14 14 -57 16 -76 4z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
